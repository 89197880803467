/**
 * Register resource for async load with require-bundle
 */
(function(){

    'use strict';

    
    // Bail if require bundle or server variables not loaded
    if ( ! requireBundle || ! rdnartsCustomizationsSettings ) return;
    
    var settings = rdnartsCustomizationsSettings,
        ver = rdnartsCustomizationsSettings.assetsVersion;

    // Plugin script bundles
    requireBundle.register( 'product-request-price', [ settings.jsPath + 'product-request-price' + ver + '.min.js', ] );
    requireBundle.register( 'product-shipping-costs', [ settings.jsPath + 'product-shipping-costs' + ver + '.min.js', ] );
    requireBundle.register( 'product-grid-view', [ settings.jsPath + 'product-grid-view' + ver + '.min.js', ] );
    requireBundle.register( 'product-image-maximize', [ settings.jsPath + 'product-image-maximize' + ver + '.min.js', ] );
    requireBundle.register( 'product-variations', [ settings.jsPath + 'product-variations' + ver + '.min.js', ] );

})();
